<template>
  <div class="auth-wrapper auth-v1 px-2">  
	    <div class="py-2">
		 <b-img
				v-if="UI=='bx'"	
				class="m-2"
				center
				:src="require('@/assets/images/logo/BOXEA-LOGO.png')"
			/>
			<b-img
				v-if="UI=='zx'"	
				class="m-2"
				center
				:src="require('@/assets/images/logo/IZIBOX-LOGO.png')"
			/>
			<b-img
				v-if="UI=='dm'"	
				class="m-2"
				center
				:src="require('@/assets/images/logo/DOMICIALIZ-LOGO.png')"
			/>
			<b-card 
				v-if="etapeencour == 1"	
			>
			</b-card>
			<b-card 
				v-if="etapeencour == 2"	
			>
				<div class="text-center">
					<div class="h1 font-weight-bolder text-primary my-md-0 ">
						VOTRE DEVIS
					</div>
				</div>
				<div class="text-center">
					<div class="h4 font-weight-normal text-uppercase my-md-0 ">
					 Pour le box <strong>{{libsoustaille}}</strong>
					</div>
				</div>
				<hr>
				<!-- soustaillechoisietype=0 -->
				<div 
					v-if="soustaillechoisietype == 0"
				>
					<b-card 
							class="mt-0 text-center"
						>
						<b-card-text
							class="h2 font-weight-normal text-uppercase my-md-0"
						>
							<strong>PLUS PETIT<br>
							{{soustaillechoisiepluspetit[0].libelle}}</strong>
						</b-card-text>
						<b-card-body>
						<b-row align-v="center" >
							<b-col class="text-left">
							</b-col>
							<b-col>
								<b-img
									center
									:src="soustaillechoisiepluspetit[0].image"
								/>
							</b-col>
							<b-col class="text-right">
								<b-button
									v-ripple.400="'rgba(255, 255, 255, 0.15)'"
									variant="outline-primary"
									v-on:click="soustaillechoisietype=1"
								>
								 Voir mon choix 
								</b-button>
							</b-col>
						</b-row>
						</b-card-body>
						<b-card-text >
							<hr>
							{{soustaillechoisiepluspetit[0].text}}
						</b-card-text>
						<b-button 
						variant="secondary"
						>
						Voir mon devis
					</b-button>
					</b-card>
					<hr>
					<div class="h2 font-weight-bolder text-center text-primary my-md-0 ">
						Choisir vos options pour réserver
					</div>
					<b-card-group deck>
						<b-card 
							v-for="(tarifitem, UI) in soustaillechoisiepluspetit[0].Resulttarif" :key="UI"
							bg-variant="white"
							class="text-center"
						>
							<b-card-body>
								<b-button 
									v-on:click="selecttarif(tarifitem,soustaillechoisiepluspetit[0])"
									variant="primary"
									>
									{{tarifitem.libelle}} : {{tarifitem.I_tarifttc}} TTC / mois
								</b-button>
							</b-card-body>
						</b-card>
					</b-card-group> 
				</div>
				<!-- soustaillechoisietype=1 -->
				<div 
					v-if="soustaillechoisietype == 1"
				>
					<b-card 
							class="mt-0 text-center"
						>
						<b-card-text
							class="h2 font-weight-normal text-uppercase my-md-0"
						>
							<strong>VOTRE CHOIX<br>
							{{soustaillechoisie[0].libelle}}</strong>
						</b-card-text>
						<b-card-body>
						<b-row align-v="center" >
							<b-col class="text-left">
								<b-button
									v-if="soustaillechoisiepluspetit"
									variant="outline-primary"
									v-on:click="soustaillechoisietype=0"
								>Voir plus petit 
								</b-button>
							</b-col>
							<b-col>
								<b-img
									center
									:src="soustaillechoisie[0].image"
								/>
							</b-col>
							<b-col class="text-right">
								<b-button
									v-if="soustaillechoisieplusgand"
									v-ripple.400="'rgba(255, 255, 255, 0.15)'"
									variant="outline-primary"
									v-on:click="soustaillechoisietype=2"
								>Voir plus grand
								</b-button>
							</b-col>
						</b-row>
						</b-card-body>
						<b-card-text >
							<hr>
							{{soustaillechoisie[0].text}}
						</b-card-text>
						<b-button 
							variant="secondary"
						>
							Voir mon devis
						</b-button>
					</b-card>
					<hr>
					<div class="h2 font-weight-bolder text-center text-primary my-md-0 ">
						Choisir vos options pour réserver
					</div>
					<b-card-group deck>
						<b-card 
							v-for="(tarifitem, UI) in soustaillechoisie[0].Resulttarif" :key="UI"
							bg-variant="white"
							class="text-center"
						>
							<b-card-body>
								<b-button 
									v-on:click="selecttarif(tarifitem,soustaillechoisie[0])"
									variant="primary"
									>
									{{tarifitem.libelle}} : {{tarifitem.I_tarifttc}} TTC / mois
								</b-button>
							</b-card-body>
						</b-card>
					</b-card-group> 

					<!-- <b-card-group deck>
						<b-card 
							v-for="(soustailleitem2, UI) in soustaille2" :key="UI"
							:bg-variant="soustailleitem2.itype !== 1 ? 'light' : 'white'"
							:border-variant="soustailleitem2.itype === 1 ? 'primary' : 'secondary'"
							:class="soustailleitem2.itype !== 1 ? 'mt-1 mb-1' : 'mt-0'"
							class="text-center"
						>
							<b-card-text 
								v-if="soustailleitem2.itype === 0"
							>
								Plus petit<br>
								{{soustailleitem2.libelle}}
							</b-card-text>
							<b-card-text 
								v-if="soustailleitem2.itype === 1"
								class="h2 font-weight-normal text-uppercase my-md-0"
							>
								<strong>Votre choix<br>
								{{soustailleitem2.libelle}}</strong>
							</b-card-text>
							<b-card-text 
								v-if="soustailleitem2.itype === 2"
							>
								Plus grand<br>
								{{soustailleitem2.libelle}}
							</b-card-text>
							<b-card-body>
								<b-img
									center
									fluid
									:src="soustailleitem2.image"
								/>
							</b-card-body>
							<b-card-text >
								<hr>
								{{soustailleitem2.text}}
							</b-card-text>
							<b-card-text 
								v-if="soustailleitem2.Resulttarif.length !== 0">
								<hr>
								<div class="h2 font-weight-bolder text-primary my-md-0 ">
									Choisir vos options
								</div>
								<br>
								<div 
									 v-for="(tarifitem, UI) in soustailleitem2.Resulttarif" :key="UI"
								>	
									<div
										 v-if="tarifitem.nbbox !== 0"
									>
										<b-button 
											v-on:click="tarifitem.affbt = !tarifitem.affbt"
											variant="primary"
											block
											>
											{{tarifitem.libelle}} : {{tarifitem.I_tarifttc}} TTC / mois
										</b-button>
										<b-card 
											v-if="tarifitem.affbt"
											border-variant='primary'
										>
											<b-row>
												<b-col>
													<b-button 
														variant="primary"
														>
														Voir mon devis
													</b-button>
												</b-col>
												<b-col>
													<b-button 
														v-on:click="selecttarif(tarifitem,soustailleitem2)"
														variant="primary"
														>
														Réserver le box
													</b-button>
												</b-col>
											</b-row>
										</b-card >
									</div>
									<div
										 v-if="tarifitem.nbbox === 0"
									>
										<b-button 
											v-on:click="tarifitem.affbt = !tarifitem.affbt"
											variant="outline-primary"
											block
											>
											{{tarifitem.libelle}} : {{tarifitem.I_tarifttc}} TTC / mois
										</b-button>
										<b-card 
											v-if="tarifitem.affbt"
											border-variant='primary'
										>
											<div> 
												Plus de disponibilté	
											</div>
											<b-row>
												<b-col>
													<b-button 
														variant="secondary"
														>
														Voir mon devis
													</b-button>
												</b-col>
												<b-col>
													<b-button 
														v-on:click="selecttarif2(tarifitem,soustailleitem2)"
														variant="secondary"
														>
														Être mis sur liste d'attente 
													</b-button>
												</b-col>
											</b-row>
										</b-card >
									</div>
									<br>
								</div>	
							</b-card-text>
							<b-card-text 
								v-if="soustailleitem2.Resulttarif.length === 0">
								<hr>
								<strong>Désolé, nous n'avons plus de disponibilité</strong><br><br>
									<b-button 
										v-on:click="selecttarif2(tarifitem,soustailleitem2)"
										variant="outline-secondary"
										block
										>
										Être mis sur liste d'attente 
									</b-button>
							</b-card-text>
						</b-card>
					</b-card-group> -->
				</div>
				<!-- soustaillechoisietype=2 -->
				<div 
					v-if="soustaillechoisietype == 2"
				>
					<b-card 
							class="mt-0 text-center"
						>
						<b-card-text
							class="h2 font-weight-normal text-uppercase my-md-0"
						>
							<strong>PLUS GRAND<br>
							{{soustaillechoisieplusgand[0].libelle}}</strong>
						</b-card-text>
						<b-card-body>
						<b-row align-v="center" >
							<b-col class="text-left">
								<b-button
									v-ripple.400="'rgba(255, 255, 255, 0.15)'"
									variant="outline-primary"
									v-on:click="soustaillechoisietype=1"
								>
								 Voir mon choix 
								</b-button>
							</b-col>
							<b-col>
								<b-img
									center
									:src="soustaillechoisieplusgand[0].image"
								/>
							</b-col>
							<b-col class="text-right">

							</b-col>
						</b-row>
						</b-card-body>
						<b-card-text >
							<hr>
							{{soustaillechoisieplusgand[0].text}}
						</b-card-text>
						<b-button 

							variant="secondary"
							>
							Voir mon devis
						</b-button>
					</b-card>
					<hr>
					<div class="h2 font-weight-bolder text-center text-primary my-md-0 ">
						Choisir vos options pour réserver
					</div>
					<b-card-group deck>
						<b-card 
							v-for="(tarifitem, UI) in soustaillechoisieplusgand[0].Resulttarif" :key="UI"
							bg-variant="white"
							class="text-center"
						>
							<b-card-body>
								<b-button 
									v-on:click="selecttarif(tarifitem,soustaillechoisieplusgand[0])"
									variant="primary"
									>
									{{tarifitem.libelle}} : {{tarifitem.I_tarifttc}} TTC / mois
								</b-button>
							</b-card-body>
						</b-card>
					</b-card-group> 
				</div>
				<hr>
				<b-alert
				  variant="success"
				  show
				>
					<div class="alert-body text-center">
						<span>L'Accès Piéton vous permet de garer votre véhicule sur des places à proximité votre Box.<br>
L'Accès Drive vous permet de garer votre véhicule devant votre Box.</span>
					</div>
				</b-alert>
			</b-card>
			<b-card 
				v-if="etapeencour == 3"
			>
				<div class="text-center">
					<div class="h1 font-weight-bolder text-secondary my-md-0 ">
						RESERVATION
					</div>
				</div><br>
				<b-row offset-md="4" >
					<b-col>
						<b-card
							border-variant="secondary"
							class="shadow-none"
						>
							<div class="h2 font-weight-bolder text-secondary my-md-0 ">
								Date d'entrée souhaitée
							</div>		
							<b-input-group class="input-group-merge">
								<b-form-datepicker
									id="dateInput"
									v-model="datedebut"
									placeholder="Choisir une date"
									:min="min"
									:max="max"			   
									locale="fr"
									selected-variant="primary"
									hide-header
									class="mb-1"
									@context="onContext"
								/>
							</b-input-group>
							<div>
								<div class="h4 font-weight-bolder text-uppercase">
									Je choisis une assurance complémentaire 
								</div>	
								<b-form-group>
									 <v-select
										v-model="selectedassurance"
										label="libelle"
										placeholder="Choisir une assurance"
										:options="assurance"  
									  />
								</b-form-group>
							</div>
							<hr v-if="!bcodeok">
							<div class="h5" v-if="!bcodeok">
								Par mesure de sécurité, Merci de saisir le code reçu sur par e-mail
							</div>
							<b-form v-if="!bcodeok">
								<b-row  >
									<b-col cols="6">
										  <b-input-group class="input-group-merge">
											<b-input-group-prepend is-text>
											  <feather-icon icon="MailIcon" />
											</b-input-group-prepend>
											<b-form-input
												v-model="CEMAIL"
												id="hi-email"
												type="email"
												placeholder="Email"
											/>
										  </b-input-group>
									</b-col>
									<b-col cols="6">
										<b-button
										v-ripple.400="'rgba(186, 191, 199, 0.15)'"
										variant="outline-secondary"
										@click="sendcode"
										>
										  Renvoyer le code 
										</b-button>
									</b-col>
								</b-row>
								<br>
								<b-row >
									<b-col cols="3">
										<b-input-group class="input-group-merge">
											<b-input-group-prepend is-text>
											  <feather-icon icon="ShieldIcon" />
											</b-input-group-prepend>
											<b-form-input
												v-model="CCODE"
												id="hi-code"
												placeholder="0000"
											/>
										</b-input-group>
									</b-col>
									<b-col cols="6" class="text-left">
										<b-button
											v-ripple.400="'rgba(255, 255, 255, 0.15)'"
											variant="primary"
											class="mr-1"
											@click="verifcode"
										>
										  Valider mon e-mail
										</b-button>
									</b-col>
								</b-row>
							</b-form>
						</b-card>
					</b-col>
					<b-col>
						<b-card
							border-variant="secondary"
							class="shadow-none"
						>
							<div class="h5 font-weight-bolder text-uppercase">
								Vos informations
							</div>
							<b-form @submit.prevent>
								<b-row>
									<b-col cols="3">
									<b-form-checkbox
									  v-model="bsoc"
									  value="1"
									  class="custom-control-primary"
									>
										<span class="h5 font-weight-bolder text-uppercase">PROFESSIONNEL</span> 
									</b-form-checkbox>
									</b-col>
									<b-col cols="9">
										<b-form-group
										  v-if="bsoc"
										  label="Raison sociale *"
										  label-for="hi-rs"
										  label-cols-md="3"
										>
										  <b-input-group class="input-group-merge">
											<b-input-group-prepend is-text>
											  <feather-icon icon="HomeIcon" />
											</b-input-group-prepend>
											<b-form-input
												v-model="CRS"
												id="hi-rs"
												placeholder="Raison sociale"
											/>
										  </b-input-group>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row 	v-if="bsoc">
									<b-col cols="6">
										<b-form-group
										  label="SIRET *"
										  label-for="hi-rs"
										  label-cols-md="3"
										>
										  <b-input-group class="input-group-merge">
											<b-input-group-prepend is-text>
											</b-input-group-prepend>
											<b-form-input
												v-model="CSIRET"
												id="hi-rs"
												placeholder="N° Siret"
											/>
										  </b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="6">
										<b-form-group
										  label="N° TVA *"
										  label-for="hi-rs"
										  label-cols-md="3"
										>
										  <b-input-group class="input-group-merge">
											<b-input-group-prepend is-text>
											</b-input-group-prepend>
											<b-form-input
												v-model="CTVA"
												id="hi-rs"
												placeholder="N° de TVA intracommunautaire"
											/>
										  </b-input-group>
										</b-form-group>
									</b-col>
								</b-row><br>
								<b-row>
									<b-col cols="6">
										<b-form-group
											label="Civilité"
											label-for="hi-civ"
											 label-cols-md="3"
										>
										  <v-select
											id="hi-civ"
											v-model="selectcivilite"
											label="name"
											placeholder="Choisir une civilité"
											:options="civilite"   
										  />
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
								  <b-col cols="6">
									<b-form-group
									  label="Nom *"
									  label-for="hi-nom"
									  label-cols-md="3"
									>
									  <b-input-group class="input-group-merge">
										<b-input-group-prepend is-text>
										  <feather-icon icon="UserIcon" />
										</b-input-group-prepend>
										<b-form-input
											v-model="CNOM"
											id="hi-nom"
											placeholder="Nom"
										/>
									  </b-input-group>
									</b-form-group>
								  </b-col>
								  <b-col cols="6">
									<b-form-group
										label="Prénom *"
										label-for="hi-prenom"
										label-cols-md="3"
									>
									  <b-input-group class="input-group-merge">
										<b-input-group-prepend is-text>
										  <feather-icon icon="UserIcon" />
										</b-input-group-prepend>
										<b-form-input
											v-model="CPRENOM"
											id="hi-prenom"
											placeholder="Prénom"
										/>
									  </b-input-group>
									</b-form-group>
								  </b-col>
								  <b-col cols="6">
									<b-form-group
									  label="Email *"
									  label-for="hi-email"
									  label-cols-md="3"
									>
									  <b-input-group class="input-group-merge">
										<b-input-group-prepend is-text>
										  <feather-icon icon="MailIcon" />
										</b-input-group-prepend>
										<b-form-input
											v-model="CEMAIL"
											id="hi-email"
											type="email"
											placeholder="Email"
										/>
									  </b-input-group>
									</b-form-group>
								  </b-col>
								  <b-col cols="6">
									<b-form-group
									  label="Mobile *"
									  label-for="hi-number"
									  label-cols-md="3"
									>
									  <b-input-group class="input-group-merge">
										<b-input-group-prepend is-text>
										  <feather-icon icon="SmartphoneIcon" />
										</b-input-group-prepend>
										<b-form-input
											v-model="CPORT"
											id="hi-number"
											placeholder="Mobile"
										/>
									  </b-input-group>
									</b-form-group>
								  </b-col>
								</b-row>
								* informations obligatoires (Le numéro de portable est indispensable pour la contractualisation et l'accès à votre Box)
								<hr>
								<b-row>
								  <b-col cols="12">
									<b-form-group
									  label="Adresse"
									  label-for="hi-adresse"
									>
									  <b-input-group class="input-group-merge">
										<b-form-input
											v-model="CADRESSE"
											id="hi-adresse"
											placeholder="Adresse"
										/>
									  </b-input-group>
									</b-form-group>
								  </b-col>
								</b-row>
								<b-row>
								  <b-col cols="3">
									<b-form-group
									  label="Code postal"
									  label-for="hi-cp"
									>
									  <b-input-group class="input-group-merge">
										<b-form-input
											v-model="CCP"
											id="hi-cp"
											placeholder="Code postal"
										/>
									  </b-input-group>
									</b-form-group>
								  </b-col>
								  <b-col cols="4">
									<b-form-group
									  label="Ville"
									  label-for="hi-ville"
									>
									  <b-form-input
										v-model="CVILLE"
										id="hi-ville"
										placeholder="Ville"
										/>
									</b-form-group>
								  </b-col>
								  <b-col cols="5">
									<b-form-group
									  label="Pays"
									  label-for="hi-Pays"
									>
									  <v-select
										id="hi-Pays"
										v-model="selectedpays"
										label="name"
										placeholder="Choisir un pays"
										:options="pays"   
									  />
									</b-form-group>
								  </b-col>
								</b-row>

							</b-form>
						</b-card>
					</b-col>
				</b-row>
				<hr>
				<b-row offset-md="4" >
					<b-col class="text-left">
						<b-button
							v-ripple.400="'rgba(186, 191, 199, 0.15)'"
							type="reset"
							variant="outline-secondary"
							@click="etapeencour=2"
						>
						  Precedent 
						</b-button>
					</b-col>
					<b-col class="text-right">
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							type="submit"
							variant="primary"
							class="mr-1"
							@click="goetape4"
						>
						  suivant
						</b-button>
					</b-col>
				</b-row>	
				<hr>
			</b-card>
			<b-card 
				v-if="etapeencour == 4"
			>
				<div class="text-center">
					<div class="h4 font-weight-bolder text-uppercase">
						Récapitulatif
					</div>
				</div>
<b-row offset-md="4" >
						<b-col>
							<b-card
								border-variant="secondary"
								bg-variant="secondary"
								class="shadow-none"
							>
								<span class="h5 font-weight-bolder text-white text-uppercase">
									Début du contrat : {{libdatedebut}}
								</span><br>	
								<span class="text-white">
									Récupération de vos clés du Lundi au Vendredi de 08h30 à 12h et de 13h30 à 16h30<br>et le Samedi de 08h30 à 12h
								</span><br>
							</b-card>
							<b-card
								v-if="selectedassurance"
								border-variant="secondary"
								bg-variant="secondary"
								class="shadow-none"
							>
								<span class="h5 font-weight-bolder text-white text-uppercase">
									Assurance complémentaire : {{selectedassurance.libelle}}
								</span><br>	
							</b-card>
							<b-card
								border-variant="secondary"
								bg-variant="secondary"
								class="shadow-none"
							>
								<div class="h5 font-weight-bolder text-white text-uppercase">
									Votre box
								</div>
								<div class="text-white">
									 {{soustaillechoixfinal.libelle}} - {{tarifshoisi.libelle}} - {{tarifshoisi.I_tarifttc}} TTC
								</div>
								<div class="text-white">
									<small>
										{{soustaillechoixfinal.libelle}}
									</small><br> 
									<span class="text-white-50">
										{{soustaillechoixfinal.text}}
									</span>
								</div>
							</b-card>
									</b-col>
									<b-col>
										<b-card
											border-variant="secondary"
											class="shadow-none"
										>
											<div class="h5 font-weight-bolder text-uppercase">
												Vos informations
											</div>
											<b-row 	v-if="bsoc">
												<b-col cols="12">
													Raison sociale : {{CRS}}
												</b-col>
											</b-row>
											<b-row 	v-if="bsoc">
												<b-col cols="6">
													SIRET : {{CSIRET}}
												</b-col>
												<b-col cols="6">
													N° TVA  : {{CTVA}}
												</b-col>
											</b-row><br>
											<b-row>
												<b-col cols="6">
													Civilité : {{selectcivilite.name}}
												</b-col>
											</b-row>
											<b-row>
											  <b-col cols="6">
												Nom : {{CNOM}}
											  </b-col>
											  <b-col cols="6">
												Prénom : {{CPRENOM}}
											  </b-col>
											  <b-col cols="6">
												Email : {{CEMAIL}}
											  </b-col>
											  <b-col cols="6">
												Mobile : {{CPORT}}
											  </b-col>
											</b-row>
											<b-row>
											  <b-col cols="12">
												Adresse : {{CADRESSE}}
											  </b-col>
											</b-row>
											<b-row>
											  <b-col cols="6">
												{{CCP}} {{CVILLE}}
											  </b-col>
											  <b-col cols="6">
												Pays : {{selectedpays.name}}
											  </b-col>
											</b-row>
										</b-card>
										<br><br>
										<h5 class="text-center">Vous serez ensuite amené à payer des arrhes de <strong>{{tarifshoisi.I_arrhe}}€</strong></h5>
									</b-col>
								</b-row>
								<hr>
								<b-row offset-md="4" >
									<b-col class="text-left">
										<b-button
											v-ripple.400="'rgba(186, 191, 199, 0.15)'"
											type="reset"
											variant="outline-secondary"
											@click="etapeencour=3"
										>
										  Precedent 
										</b-button>
									</b-col>
									<b-col class="text-right">
										<b-button
											v-ripple.400="'rgba(255, 255, 255, 0.15)'"
											type="submit"
											variant="primary"
											class="mr-1"
											@click="goetape5"
										>
										  Valider la réservation et procéder au paiement des arrhes de {{tarifshoisi.I_arrhe}}€
										</b-button>
									</b-col>
								</b-row>	
			</b-card>
			<b-card 
				v-if="etapeencour == 5"
			>
				<h6>Préparation du paiement</h6>
				<div 
					 v-if="affrelancegostripe">
					<h6><strong>Erreur lors de demande</strong></h6>
					<b-button
						@click="goetape5"
					>Relancer la demande</b-button>
				</div>	
			</b-card>
			<b-card 
				v-if="etapeencour == 6"
			>
				<h6>Paiement des arrhes de <strong>{{this.caution}}{{this.infobox.C_currency}}</strong></h6>
				<stripe-element-payment
				  ref="paymentRef"
				  :pk="pk"
				  :elements-options="elementsOptions"
				  :confirm-params="confirmParams"
				/><br>
				<b-button @click="pay">Payer {{this.caution}}{{this.infobox.C_currency}}</b-button>
			</b-card>
			<b-card>
				<b-progress
					:max="maxetape"
					animated
					variant="secondary"
				>	
				<b-progress-bar :value="etapeencour" class="demo-vertical-spacing" variant="primary">
					  <strong> Etape {{ etapeencour }} sur {{ maxetape }}</strong>
					</b-progress-bar>
				</b-progress>
			</b-card>
				<small class="text-muted">{{ version_resa }}</small>
		</div>
  </div>
</template>
<script>
import { BCard, BButton, BProgress, BProgressBar, BImg, BRow, BCol, BFormInput, BFormCheckbox, BForm, BFormGroup, BInputGroup, BInputGroupPrepend, BCardText, BCardGroup, BCardBody, BFormDatepicker, BAlert } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { useRouter } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { StripeElementPayment } from '@vue-stripe/vue-stripe'
	
export default {
	components: {
		BCard,
		BButton,
		BProgress, 
		BProgressBar, 
		BImg,
		BRow, 
		BCol,
		BFormInput,
		BFormCheckbox, 
		BForm,
		BFormGroup,
		BInputGroup,
		BInputGroupPrepend,
		BCardText,
		BCardGroup,
		BCardBody,
		BFormDatepicker,
		vSelect,
		StripeElementPayment,
		BAlert,
	},
	directives: {
		Ripple,
	},
	data() {
		const now = new Date()
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
		const minDate = new Date(today)
		return {
			version_resa: 'V1.0.0',
			bsoc: false,
			CRS: '',
			CSIRET: '',
			CTVA: '',
			CNOM: '',
			CPRENOM: '',
			CADRESSE: '',
			CCP: '',
			CVILLE: '',
			CPORT: '',
			CEMAIL: '',
			CCIVILITE: '',
			CCODE: '',
			UISOC: '',
			bcode: false,
			bcodeok: false,
			MMESS: '',
			etapeencour: 1,
			maxetape: 6,
			min: minDate,
			max: minDate,
			devis: [],
			taille: [],
			soustaille: [],
			soustaille2: [],
			soustaillechoisiepluspetit: [],
			soustaillechoisie: [],
			soustaillechoisieplusgand: [],
			soustaillechoisietype: 1,
			soustaillearif1: [],
			soustaillearif2: [],
			soustaillearif3: [],
			tarifshoisi: [],
			taillechoisielibelle: '',
			taillechoisieUI: '',
			soustaillechoixfinal: [],
			libsoc: '',
			libtaille: '',
			libsoustaille: '',
			libsoustailleui: '',
			libdatedebut: '',
			datedebut: '',
			C_UIassurance: '',
			selectedassurance: [],
			assurance: [],
			caution: 0,
			infobox: [],
			confirmParams: {
				return_url: '#', // success url
			},
			elementsOptions: {
				appearance: {}, // appearance options
			},
			pk: '',

			selectedpays: { name: 'Réunion', code: 'RE' },
			civilite: [{
					name: 'NC',
					id: '1',
				},
				{
					name: 'Monsieur',
					id: '4',
				},
				{
					name: 'Madame',
					id: '2',
				},
				{
					name: 'Mademoiselle',
					id: '3',
				},
			],
			selectcivilite: { name: 'NC', id: '1' },
			pays: [{
				name: 'Afghanistan',
				code: 'AF',
			}, {
				name: 'Åland Islands',
				code: 'AX',
			}, {
				name: 'Albania',
				code: 'AL',
			}, {
				name: 'Algeria',
				code: 'DZ',
			}, {
				name: 'American Samoa',
				code: 'AS',
			}, {
				name: 'Andorra',
				code: 'AD',
			}, {
				name: 'Angola',
				code: 'AO',
			}, {
				name: 'Anguilla',
				code: 'AI',
			}, {
				name: 'Antarctica',
				code: 'AQ',
			}, {
				name: 'Antigua and Barbuda',
				code: 'AG',
			}, {
				name: 'Argentina',
				code: 'AR',
			}, {
				name: 'Armenia',
				code: 'AM',
			}, {
				name: 'Aruba',
				code: 'AW',
			}, {
				name: 'Australia',
				code: 'AU',
			}, {
				name: 'Austria',
				code: 'AT',
			}, {
				name: 'Azerbaijan',
				code: 'AZ',
			}, {
				name: 'Bahamas',
				code: 'BS',
			}, {
				name: 'Bahrain',
				code: 'BH',
			}, {
				name: 'Bangladesh',
				code: 'BD',
			}, {
				name: 'Barbados',
				code: 'BB',
			}, {
				name: 'Belarus',
				code: 'BY',
			}, {
				name: 'Belgium',
				code: 'BE',
			}, {
				name: 'Belize',
				code: 'BZ',
			}, {
				name: 'Benin',
				code: 'BJ',
			}, {
				name: 'Bermuda',
				code: 'BM',
			}, {
				name: 'Bhutan',
				code: 'BT',
			}, {
				name: 'Bolivia, Plurinational State of',
				code: 'BO',
			}, {
				name: 'Bonaire, Sint Eustatius and Saba',
				code: 'BQ',
			}, {
				name: 'Bosnia and Herzegovina',
				code: 'BA',
			}, {
				name: 'Botswana',
				code: 'BW',
			}, {
				name: 'Bouvet Island',
				code: 'BV',
			}, {
				name: 'Brazil',
				code: 'BR',
			}, {
				name: 'British Indian Ocean Territory',
				code: 'IO',
			}, {
				name: 'Brunei Darussalam',
				code: 'BN',
			}, {
				name: 'Bulgaria',
				code: 'BG',
			}, {
				name: 'Burkina Faso',
				code: 'BF',
			}, {
				name: 'Burundi',
				code: 'BI',
			}, {
				name: 'Cambodia',
				code: 'KH',
			}, {
				name: 'Cameroon',
				code: 'CM',
			}, {
				name: 'Canada',
				code: 'CA',
			}, {
				name: 'Cape Verde',
				code: 'CV',
			}, {
				name: 'Cayman Islands',
				code: 'KY',
			}, {
				name: 'Central African Republic',
				code: 'CF',
			}, {
				name: 'Chad',
				code: 'TD',
			}, {
				name: 'Chile',
				code: 'CL',
			}, {
				name: 'China',
				code: 'CN',
			}, {
				name: 'Christmas Island',
				code: 'CX',
			}, {
				name: 'Cocos (Keeling) Islands',
				code: 'CC',
			}, {
				name: 'Colombia',
				code: 'CO',
			}, {
				name: 'Comoros',
				code: 'KM',
			}, {
				name: 'Congo',
				code: 'CG',
			}, {
				name: 'Congo, the Democratic Republic of the',
				code: 'CD',
			}, {
				name: 'Cook Islands',
				code: 'CK',
			}, {
				name: 'Costa Rica',
				code: 'CR',
			}, {
				name: 'Côte d\'Ivoire',
				code: 'CI',
			}, {
				name: 'Croatia',
				code: 'HR',
			}, {
				name: 'Cuba',
				code: 'CU',
			}, {
				name: 'Curaçao',
				code: 'CW',
			}, {
				name: 'Cyprus',
				code: 'CY',
			}, {
				name: 'Czech Republic',
				code: 'CZ',
			}, {
				name: 'Denmark',
				code: 'DK',
			}, {
				name: 'Djibouti',
				code: 'DJ',
			}, {
				name: 'Dominica',
				code: 'DM',
			}, {
				name: 'Dominican Republic',
				code: 'DO',
			}, {
				name: 'Ecuador',
				code: 'EC',
			}, {
				name: 'Egypt',
				code: 'EG',
			}, {
				name: 'El Salvador',
				code: 'SV',
			}, {
				name: 'Equatorial Guinea',
				code: 'GQ',
			}, {
				name: 'Eritrea',
				code: 'ER',
			}, {
				name: 'Estonia',
				code: 'EE',
			}, {
				name: 'Ethiopia',
				code: 'ET',
			}, {
				name: 'Falkland Islands (Malvinas)',
				code: 'FK',
			}, {
				name: 'Faroe Islands',
				code: 'FO',
			}, {
				name: 'Fiji',
				code: 'FJ',
			}, {
				name: 'Finland',
				code: 'FI',
			}, {
				name: 'France',
				code: 'FR',
			}, {
				name: 'French Guiana',
				code: 'GF',
			}, {
				name: 'French Polynesia',
				code: 'PF',
			}, {
				name: 'French Southern Territories',
				code: 'TF',
			}, {
				name: 'Gabon',
				code: 'GA',
			}, {
				name: 'Gambia',
				code: 'GM',
			}, {
				name: 'Georgia',
				code: 'GE',
			}, {
				name: 'Germany',
				code: 'DE',
			}, {
				name: 'Ghana',
				code: 'GH',
			}, {
				name: 'Gibraltar',
				code: 'GI',
			}, {
				name: 'Greece',
				code: 'GR',
			}, {
				name: 'Greenland',
				code: 'GL',
			}, {
				name: 'Grenada',
				code: 'GD',
			}, {
				name: 'Guadeloupe',
				code: 'GP',
			}, {
				name: 'Guam',
				code: 'GU',
			}, {
				name: 'Guatemala',
				code: 'GT',
			}, {
				name: 'Guernsey',
				code: 'GG',
			}, {
				name: 'Guinea',
				code: 'GN',
			}, {
				name: 'Guinea-Bissau',
				code: 'GW',
			}, {
				name: 'Guyana',
				code: 'GY',
			}, {
				name: 'Haiti',
				code: 'HT',
			}, {
				name: 'Heard Island and McDonald Islands',
				code: 'HM',
			}, {
				name: 'Holy See (Vatican City State)',
				code: 'VA',
			}, {
				name: 'Honduras',
				code: 'HN',
			}, {
				name: 'Hong Kong',
				code: 'HK',
			}, {
				name: 'Hungary',
				code: 'HU',
			}, {
				name: 'Iceland',
				code: 'IS',
			}, {
				name: 'India',
				code: 'IN',
			}, {
				name: 'Indonesia',
				code: 'ID',
			}, {
				name: 'Iran, Islamic Republic of',
				code: 'IR',
			}, {
				name: 'Iraq',
				code: 'IQ',
			}, {
				name: 'Ireland',
				code: 'IE',
			}, {
				name: 'Isle of Man',
				code: 'IM',
			}, {
				name: 'Israel',
				code: 'IL',
			}, {
				name: 'Italy',
				code: 'IT',
			}, {
				name: 'Jamaica',
				code: 'JM',
			}, {
				name: 'Japan',
				code: 'JP',
			}, {
				name: 'Jersey',
				code: 'JE',
			}, {
				name: 'Jordan',
				code: 'JO',
			}, {
				name: 'Kazakhstan',
				code: 'KZ',
			}, {
				name: 'Kenya',
				code: 'KE',
			}, {
				name: 'Kiribati',
				code: 'KI',
			}, {
				name: 'Korea, Democratic People\'s Republic of',
				code: 'KP',
			}, {
				name: 'Korea, Republic of',
				code: 'KR',
			}, {
				name: 'Kuwait',
				code: 'KW',
			}, {
				name: 'Kyrgyzstan',
				code: 'KG',
			}, {
				name: 'Lao People\'s Democratic Republic',
				code: 'LA',
			}, {
				name: 'Latvia',
				code: 'LV',
			}, {
				name: 'Lebanon',
				code: 'LB',
			}, {
				name: 'Lesotho',
				code: 'LS',
			}, {
				name: 'Liberia',
				code: 'LR',
			}, {
				name: 'Libya',
				code: 'LY',
			}, {
				name: 'Liechtenstein',
				code: 'LI',
			}, {
				name: 'Lithuania',
				code: 'LT',
			}, {
				name: 'Luxembourg',
				code: 'LU',
			}, {
				name: 'Macao',
				code: 'MO',
			}, {
				name: 'Macedonia, the Former Yugoslav Republic of',
				code: 'MK',
			}, {
				name: 'Madagascar',
				code: 'MG',
			}, {
				name: 'Malawi',
				code: 'MW',
			}, {
				name: 'Malaysia',
				code: 'MY',
			}, {
				name: 'Maldives',
				code: 'MV',
			}, {
				name: 'Mali',
				code: 'ML',
			}, {
				name: 'Malta',
				code: 'MT',
			}, {
				name: 'Marshall Islands',
				code: 'MH',
			}, {
				name: 'Martinique',
				code: 'MQ',
			}, {
				name: 'Mauritania',
				code: 'MR',
			}, {
				name: 'Mauritius',
				code: 'MU',
			}, {
				name: 'Mayotte',
				code: 'YT',
			}, {
				name: 'Mexico',
				code: 'MX',
			}, {
				name: 'Micronesia, Federated States of',
				code: 'FM',
			}, {
				name: 'Moldova, Republic of',
				code: 'MD',
			}, {
				name: 'Monaco',
				code: 'MC',
			}, {
				name: 'Mongolia',
				code: 'MN',
			}, {
				name: 'Montenegro',
				code: 'ME',
			}, {
				name: 'Montserrat',
				code: 'MS',
			}, {
				name: 'Morocco',
				code: 'MA',
			}, {
				name: 'Mozambique',
				code: 'MZ',
			}, {
				name: 'Myanmar',
				code: 'MM',
			}, {
				name: 'Namibia',
				code: 'NA',
			}, {
				name: 'Nauru',
				code: 'NR',
			}, {
				name: 'Nepal',
				code: 'NP',
			}, {
				name: 'Netherlands',
				code: 'NL',
			}, {
				name: 'New Caledonia',
				code: 'NC',
			}, {
				name: 'New Zealand',
				code: 'NZ',
			}, {
				name: 'Nicaragua',
				code: 'NI',
			}, {
				name: 'Niger',
				code: 'NE',
			}, {
				name: 'Nigeria',
				code: 'NG',
			}, {
				name: 'Niue',
				code: 'NU',
			}, {
				name: 'Norfolk Island',
				code: 'NF',
			}, {
				name: 'Northern Mariana Islands',
				code: 'MP',
			}, {
				name: 'Norway',
				code: 'NO',
			}, {
				name: 'Oman',
				code: 'OM',
			}, {
				name: 'Pakistan',
				code: 'PK',
			}, {
				name: 'Palau',
				code: 'PW',
			}, {
				name: 'Palestine, State of',
				code: 'PS',
			}, {
				name: 'Panama',
				code: 'PA',
			}, {
				name: 'Papua New Guinea',
				code: 'PG',
			}, {
				name: 'Paraguay',
				code: 'PY',
			}, {
				name: 'Peru',
				code: 'PE',
			}, {
				name: 'Philippines',
				code: 'PH',
			}, {
				name: 'Pitcairn',
				code: 'PN',
			}, {
				name: 'Poland',
				code: 'PL',
			}, {
				name: 'Portugal',
				code: 'PT',
			}, {
				name: 'Puerto Rico',
				code: 'PR',
			}, {
				name: 'Qatar',
				code: 'QA',
			}, {
				name: 'Réunion',
				code: 'RE',
			}, {
				name: 'Romania',
				code: 'RO',
			}, {
				name: 'Russian Federation',
				code: 'RU',
			}, {
				name: 'Rwanda',
				code: 'RW',
			}, {
				name: 'Saint Barthélemy',
				code: 'BL',
			}, {
				name: 'Saint Helena, Ascension and Tristan da Cunha',
				code: 'SH',
			}, {
				name: 'Saint Kitts and Nevis',
				code: 'KN',
			}, {
				name: 'Saint Lucia',
				code: 'LC',
			}, {
				name: 'Saint Martin (French part)',
				code: 'MF',
			}, {
				name: 'Saint Pierre and Miquelon',
				code: 'PM',
			}, {
				name: 'Saint Vincent and the Grenadines',
				code: 'VC',
			}, {
				name: 'Samoa',
				code: 'WS',
			}, {
				name: 'San Marino',
				code: 'SM',
			}, {
				name: 'Sao Tome and Principe',
				code: 'ST',
			}, {
				name: 'Saudi Arabia',
				code: 'SA',
			}, {
				name: 'Senegal',
				code: 'SN',
			}, {
				name: 'Serbia',
				code: 'RS',
			}, {
				name: 'Seychelles',
				code: 'SC',
			}, {
				name: 'Sierra Leone',
				code: 'SL',
			}, {
				name: 'Singapore',
				code: 'SG',
			}, {
				name: 'Sint Maarten (Dutch part)',
				code: 'SX',
			}, {
				name: 'Slovakia',
				code: 'SK',
			}, {
				name: 'Slovenia',
				code: 'SI',
			}, {
				name: 'Solomon Islands',
				code: 'SB',
			}, {
				name: 'Somalia',
				code: 'SO',
			}, {
				name: 'South Africa',
				code: 'ZA',
			}, {
				name: 'South Georgia and the South Sandwich Islands',
				code: 'GS',
			}, {
				name: 'South Sudan',
				code: 'SS',
			}, {
				name: 'Spain',
				code: 'ES',
			}, {
				name: 'Sri Lanka',
				code: 'LK',
			}, {
				name: 'Sudan',
				code: 'SD',
			}, {
				name: 'Suriname',
				code: 'SR',
			}, {
				name: 'Svalbard and Jan Mayen',
				code: 'SJ',
			}, {
				name: 'Swaziland',
				code: 'SZ',
			}, {
				name: 'Sweden',
				code: 'SE',
			}, {
				name: 'Switzerland',
				code: 'CH',
			}, {
				name: 'Syrian Arab Republic',
				code: 'SY',
			}, {
				name: 'Taiwan, Province of China',
				code: 'TW',
			}, {
				name: 'Tajikistan',
				code: 'TJ',
			}, {
				name: 'Tanzania, United Republic of',
				code: 'TZ',
			}, {
				name: 'Thailand',
				code: 'TH',
			}, {
				name: 'Timor-Leste',
				code: 'TL',
			}, {
				name: 'Togo',
				code: 'TG',
			}, {
				name: 'Tokelau',
				code: 'TK',
			}, {
				name: 'Tonga',
				code: 'TO',
			}, {
				name: 'Trinidad and Tobago',
				code: 'TT',
			}, {
				name: 'Tunisia',
				code: 'TN',
			}, {
				name: 'Turkey',
				code: 'TR',
			}, {
				name: 'Turkmenistan',
				code: 'TM',
			}, {
				name: 'Turks and Caicos Islands',
				code: 'TC',
			}, {
				name: 'Tuvalu',
				code: 'TV',
			}, {
				name: 'Uganda',
				code: 'UG',
			}, {
				name: 'Ukraine',
				code: 'UA',
			}, {
				name: 'United Arab Emirates',
				code: 'AE',
			}, {
				name: 'United Kingdom',
				code: 'GB',
			}, {
				name: 'United States',
				code: 'US',
			}, {
				name: 'United States Minor Outlying Islands',
				code: 'UM',
			}, {
				name: 'Uruguay',
				code: 'UY',
			}, {
				name: 'Uzbekistan',
				code: 'UZ',
			}, {
				name: 'Vanuatu',
				code: 'VU',
			}, {
				name: 'Venezuela, Bolivarian Republic of',
				code: 'VE',
			}, {
				name: 'Viet Nam',
				code: 'VN',
			}, {
				name: 'Virgin Islands, British',
				code: 'VG',
			}, {
				name: 'Virgin Islands, U.S.',
				code: 'VI',
			}, {
				name: 'Wallis and Futuna',
				code: 'WF',
			}, {
				name: 'Western Sahara',
				code: 'EH',
			}, {
				name: 'Yemen',
				code: 'YE',
			}, {
				name: 'Zambia',
				code: 'ZM',
			}, {
				name: 'Zimbabwe',
				code: 'ZW',
			}],
		}
	},
	created() {
		const UIdevis = this.UI
		this.bcodeok = false
		this.$http.get(`${this.APIURL}resa/getdevis/${UIdevis}`)
		.then(response => {
			if (response.data.res === 1) {
				this.UISOC = response.data.UISOC
				if (response.data.I_etat === 10) {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : demande finalisée',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})
				} else if ((response.data.I_etat === 4) && (response.data.ID_box !== 0)) {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : demande finalisée',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})
				} else if ((response.data.I_etat === 3) && (response.data.ID_box !== 0)) {
					this.goetape6()
				} else {
					this.CEMAIL = response.data.C_email
					this.CPORT = response.data.C_port
					this.CNOM = response.data.C_nom
					this.CPRENOM = response.data.C_prenom
					this.CADRESSE = response.data.C_adresse
					this.CCP = response.data.C_cp
					this.CVILLE = response.data.C_ville
					this.bsoc = response.data.B_soc
					this.CRS = response.data.C_rs
					this.CSIRET = response.data.C_siret
					this.CTVA = response.data.C_tva
					this.bcodeok = response.data.B_codeok
					this.taillechoisielibelle = response.data.taillechoisielibelle
					this.taillechoisieUI = response.data.taillechoisieUI
					this.libsoustaille = response.data.soustaillechoisielibelle
					this.libsoustailleui = response.data.soustaillechoisieUI
					this.C_UIassurance = response.data.C_UIassurance
					if (this.C_UIassurance === '') {
						this.C_UIassurance = 'Eb65eScEY8b67PLkm5nTSC7f6Jpyxj'	
					}
					this.selectedpays = response.data.pays[0]
					this.selectcivilite = response.data.civ[0]
					this.$http.get(`${this.APIURL}resa/getassurance`)
					.then(responseass => {
						if (responseass.data.res === 1) {
							this.assurance = responseass.data.ResultAssurances
							let iassurance = 0
							this.selectedassurance = []
							for (iassurance = 0; iassurance < this.assurance.length; iassurance += 1) {
								if (this.assurance[iassurance].UI === this.C_UIassurance) {
									this.selectedassurance = this.assurance[iassurance]
								}
							}
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: 'Oups',
									icon: 'SmileIcon',
									variant: 'primary',
									text: 'Erreur : assurance non trouvées',
								},
							},
							{
								timeout: 4000,
								position: 'top-center',
							})
						}
					}) 
					this.$http.get(`${this.APIURL}resa/gettaille/${response.data.UISOC}`)
					.then(response2 => {
						if (response2.data.res === 1) {
							this.taille = response2.data.ResultTailleBox
							this.libsoc = response2.data.libelle
							const numberOfDaysToAdd = 15
							const now = new Date()
							const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
							const maxDate = new Date(today)
							maxDate.setDate(maxDate.getDate() + numberOfDaysToAdd)
							this.max = maxDate
							this.$http.get(`${this.APIURL}resa/getsoustaille/${this.taillechoisieUI}`)
							.then(response3 => {
								if (response3.data.res === 1) {
									this.soustaille = response3.data.ResultSousTailleBox
									let isoustaille = 0
									this.soustaille2 = []
									this.soustaillearif1 = []
									this.soustaillearif2 = []
									this.soustaillearif3 = []
									this.soustaillechoisie = []
									for (isoustaille = 0; isoustaille < this.soustaille.length; isoustaille += 1) {
										if (this.soustaille[isoustaille].UI === this.libsoustailleui) {
											if (isoustaille > 0) {
												// this.soustaille2.push(this.soustaille[isoustaille - 1])	
												this.soustaille2.push({
													UI: this.soustaille[isoustaille - 1].UI,
													libelle: this.soustaille[isoustaille - 1].libelle,
													image: this.soustaille[isoustaille - 1].image,
													text: this.soustaille[isoustaille - 1].text,
													nbbox: this.soustaille[isoustaille - 1].nbbox,
													Resulttarif: this.soustaille[isoustaille - 1].Resulttarif,
													itype: 0,
												})
												this.soustaillechoisiepluspetit.push({
													UI: this.soustaille[isoustaille - 1].UI,
													libelle: this.soustaille[isoustaille - 1].libelle,
													image: this.soustaille[isoustaille - 1].image,
													text: this.soustaille[isoustaille - 1].text,
													nbbox: this.soustaille[isoustaille - 1].nbbox,
													Resulttarif: this.soustaille[isoustaille - 1].Resulttarif,
													itype: 0,
												})
											}
											// this.soustaille2.push(this.soustaille[isoustaille])
											this.soustaille2.push({
												UI: this.soustaille[isoustaille].UI,
												libelle: this.soustaille[isoustaille].libelle,
												image: this.soustaille[isoustaille].image,
												text: this.soustaille[isoustaille].text,
												nbbox: this.soustaille[isoustaille].nbbox,
												Resulttarif: this.soustaille[isoustaille].Resulttarif,
												itype: 1,
											})
											this.soustaillechoisie.push({
												UI: this.soustaille[isoustaille].UI,
												libelle: this.soustaille[isoustaille].libelle,
												image: this.soustaille[isoustaille].image,
												text: this.soustaille[isoustaille].text,
												nbbox: this.soustaille[isoustaille].nbbox,
												Resulttarif: this.soustaille[isoustaille].Resulttarif,
												itype: 1,
											})
											 this.soustaillechoisietype = 1
											
											if (isoustaille < (this.soustaille.length - 1)) {
												// this.soustaille2.push(this.soustaille[isoustaille + 1])	
												this.soustaille2.push({
													UI: this.soustaille[isoustaille + 1].UI,
													libelle: this.soustaille[isoustaille + 1].libelle,
													image: this.soustaille[isoustaille + 1].image,
													text: this.soustaille[isoustaille + 1].text,
													nbbox: this.soustaille[isoustaille + 1].nbbox,
													Resulttarif: this.soustaille[isoustaille + 1].Resulttarif,
													itype: 2,
												})
												this.soustaillechoisieplusgand.push({
													UI: this.soustaille[isoustaille + 1].UI,
													libelle: this.soustaille[isoustaille + 1].libelle,
													image: this.soustaille[isoustaille + 1].image,
													text: this.soustaille[isoustaille + 1].text,
													nbbox: this.soustaille[isoustaille + 1].nbbox,
													Resulttarif: this.soustaille[isoustaille + 1].Resulttarif,
													itype: 2,
												})
											}
										} 
									}
									this.etapeencour = 2
								} else {
									this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Oups',
										icon: 'SmileIcon',
										variant: 'primary',
										text: 'Erreur : demande non trouvée',
									},
									},
									{
									timeout: 4000,
									position: 'top-center',
									})
								}
								}) 
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: 'Oups',
									icon: 'SmileIcon',
									variant: 'primary',
									text: 'Erreur : demande non trouvée',
								},
							},
							{
								timeout: 4000,
								position: 'top-center',
							})
						}
					})
				}
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'primary',
						text: 'Erreur : demande non trouvée',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})
			}
		}) 
	},
	setup() {
		const { router } = useRouter()	
		const UI = router.currentRoute.params.id
		const APIURL = 'https://api.reunibox.com/'
		return {
			UI,
			APIURL,
		}
	},
	methods: {
		selecttarif: function (tarifchoisi, soustailleitem2) {
				let berr2 = false
				if (tarifchoisi === '') {
					berr2 = true	
				}
				if (!berr2) {
					this.tarifshoisi = tarifchoisi
					this.soustaillechoixfinal = soustailleitem2
					this.etapeencour = 3
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : Merci de saisir tout les champs',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})		
				}
		},	
		selecttarif2: function (tarifchoisi, soustailleitem2) {
				let berr2 = false
				if (tarifchoisi === '') {
					berr2 = true	
				}
				if (!berr2) {
					this.tarifshoisi = tarifchoisi
					this.soustaillechoixfinal = soustailleitem2
					this.$http.post(`${this.APIURL}resa/majdevisattente`, { UIDEV: this.UI, soustaillechoisielibelle: this.soustaillechoixfinal.libelle, soustaillechoisieUI: this.libsoustailleui })
						.then(response => {
							if (response.data.res === 1) {
								 window.location.replace(response.data.urlretour)	
							} else {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Oups',
										icon: 'SmileIcon',
										variant: 'primary',
										text: 'Erreur : Erreur de connexion du serveur',
									},
								},
								{
									timeout: 4000,
									position: 'top-center',
								})	
							}	
						}) 
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : Merci de saisir tout les champs',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})		
				}
		},	
		gocontact: function () {
				let berr2 = false
				if (this.datedebut === '') {
					berr2 = true	
				}
				if (!berr2) {
					this.etapeencour = 5	
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : Merci de saisir une date',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})		
				}
		},	
		verifcode() {
			this.bcodeok = false
			const UIR = this.UI
			this.$http.post(`${this.APIURL}resa/verifcode`, { UI: UIR, code: this.CCODE })
			.then(response => {
				if (response.data.res === 1) {
					if (response.data.etat === 1) {
						this.bcodeok = true	
					} else {
						this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : Le code est incorrecte',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})	
					}
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : Erreur de connexion du serveur',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})	
				}	
			}) 
		},	
		sendcode() {
			this.bcodeok = false
			const UIR = this.UI
			// const UIRS = this.selectedsite.UI
			const UIRS = this.UISOC
			this.$http.post(`${this.APIURL}resa/sendcode`, { UI: UIR, UIS: UIRS, EMAIL: this.CEMAIL })
			.then(response => {
				if (response.data.res === 1) {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'ok',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Code : Le code est renvoyé',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})	
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : Erreur de connexion du serveur',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})	
				}	
			}) 
		},	
		goetape4() {
			let berr = false
			// let UIASS = ''
			this.totalmensuel = 0 // parseFloat(this.I_prixTTC) + parseFloat(this.ttcassurance)
			if ((this.CPORT === '') || (this.CEMAIL === '') || (this.CNOM === '') || (this.CPRENOM === '') || (this.CADRESSE === '') || (this.CCP === '') || (this.CVILLE === '')) {
				berr = true	
			}
			if (this.bsoc) {
				if ((this.CRS === '') || (this.CSIRET === '') || (this.CTVA === '')) {
					berr = true	
				}
			}
			if (!this.datedebut) {
				berr = true	
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'primary',
						text: 'Erreur : Merci de saisir une date',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})	
			}
			if (!this.bcodeok) {
				berr = true	
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'primary',
						text: 'Erreur : Merci de valider votre e-mail',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})	
			}
			if (!berr) {
				 this.libdatedebut = this.datedebut.split('-').reverse().join('/')
				// this.etapeencour = 4	
				this.$http.post(`${this.APIURL}resa/majdevis`, { UIDEV: this.UI, EMAIL: this.CEMAIL, PORT: this.CPORT, CNOM: this.CNOM, CPRENOM: this.CPRENOM, CADRESSE: this.CADRESSE, CCP: this.CCP, CVILLE: this.CVILLE, CPAYS: this.selectedpays.name, CPAYSCODE: this.selectedpays.code, BSOC: this.bsoc, CRS: this.CRS, CSIRET: this.CSIRET, CTVA: this.CTVA, soustaillechoisielibelle: this.soustaillechoixfinal.libelle, soustaillechoisieUI: this.libsoustailleui, DATEDEBUT: this.datedebut, CCIV: this.selectcivilite.id, CCILLIB: this.selectcivilite.name, soustaillechoixfinal: this.tarifshoisi.UI, C_UIassurance: this.selectedassurance.UI })
					.then(response => {
						if (response.data.res === 1) {
							 this.etapeencour = 4	
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: 'Oups',
									icon: 'SmileIcon',
									variant: 'primary',
									text: 'Erreur : Erreur de connexion du serveur',
								},
							},
							{
								timeout: 4000,
								position: 'top-center',
							})	
						}	
					}) 
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'primary',
						text: 'Erreur : Merci de saisir tout les champs',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})		
			}
		},
		goetape5() {
			let berr = false
			// let UIASS = ''
			this.totalmensuel = 0 // parseFloat(this.I_prixTTC) + parseFloat(this.ttcassurance)
			if ((this.CPORT === '') || (this.CEMAIL === '') || (this.CNOM === '') || (this.CPRENOM === '') || (this.CADRESSE === '') || (this.CCP === '') || (this.CVILLE === '')) {
				berr = true	
			}
			if (this.bsoc) {
				if ((this.CRS === '') || (this.CSIRET === '') || (this.CTVA === '')) {
					berr = true	
				}
			}
			if (!this.datedebut) {
				berr = true	
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'primary',
						text: 'Erreur : Merci de saisir une date',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})	
			}
			if (!this.bcodeok) {
				berr = true	
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'primary',
						text: 'Erreur : Merci de valider votre e-mail',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})	
			}
			if (!berr) {
				 this.libdatedebut = this.datedebut.split('-').reverse().join('/')
				// this.etapeencour = 4	
				this.$http.post(`${this.APIURL}resa/majdevis2`, { UIDEV: this.UI, UIS: this.UISOC })
					.then(response => {
						if (response.data.res === 1) {
							this.goetape6()
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: 'Oups',
									icon: 'SmileIcon',
									variant: 'primary',
									text: `Erreur : le box n'est plus disponible/${response.data.idbox}/${response.data.ID_tarif}`,
								},
							},
							{
								timeout: 4000,
								position: 'top-center',
							})		
						}
					}) 
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : Merci de saisir tout les champs',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})		
				}
		},
		goetape6() {
			this.etapeencour = 5
			this.$http.post(`${this.APIURL}resa/secret`, { UIDEV: this.UI, UIS: this.UISOC })
			.then(response => {
				if (response.data.res === 1) {
					this.infobox = response.data.infobox[0]
					this.confirmParams.return_url = response.data.urlretour
					this.elementsOptions.clientSecret = response.data.paymentIntent.client_secret
					this.pk = response.data.pk
					this.caution = response.data.paymentIntent.amount / 100
					this.etapeencour = 6	
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'primary',
							text: 'Erreur : Erreur de connexion du serveur',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})	
				}
			}) 
		},	
		submit() {
		  this.$refs.checkoutRef.redirectToCheckout()
		},
		pay() {
		  this.$refs.paymentRef.submit()
		},
	},
}
</script>
<style lang="scss">
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
   opacity: 0;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.20);
  }
  100% {
    transform: scale(1);
  }
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
